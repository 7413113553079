import { article as articleData } from "scmp-app/data";

export const MagazinesStyleTagsMap: Record<string, JSX.Element> = {
  [articleData.types.explainer.entityId]: (
    <>
      Ex<span>pl</span>ainer
    </>
  ),
  [articleData.types.opinion.entityId]: (
    <>
      O<span>pi</span>n<span>i</span>on
    </>
  ),
  [articleData.types.qa.entityId]: (
    <>
      Q<span>&</span>a
    </>
  ),
  [articleData.types.review.entityId]: (
    <>
      Re<span>v</span>iew
    </>
  ),
};
